import {FC, useCallback, useEffect, useRef, useState} from 'react';
import {
  getLotteryGames,
  LotteryGameCardItem,
  LotteryGameFloorItem,
} from './lottery.service';
import React from 'react';
import Spin from '@/components/v2/basic/spin';
import Category from '@/components/v2/business/category/category';
import CasinoGamesCard from '@/components/v2/business/category/casino-games-card';
import {getUrlParams, goTo} from '@/utils';
import MixLotteryCard from '@/components/v2/business/category/mix-lotto-card';
import styles from './lottery.module.scss';
import Footer from '@/components/v2/business/footer/footer';
import {showToast} from '@/utils/toast';
import {SafeAny} from '@/types';
import {getDigitList} from '@/pages/home/home.service';
import DigitCard from '@/components/v2/business/category/digit-card';
import CategoryTab, {iconsMap} from './components/category-tab';
const getLastOr0 = (arr: SafeAny[]) => arr[arr.length - 1] || 0;

function getIndianLotteryData(v: LotteryGameCardItem) {
  const time = +getLastOr0(v.otherParams?.split('=')) * 1000;
  return {time};
}

interface LotteryContentProps {
  tabWrapClassName?: string;
}
const LotteryContent: FC<LotteryContentProps> = ({tabWrapClassName = ''}) => {
  const [loading, setLoading] = useState(false);
  const [lotteryGames, setLotteryGames] = useState<LotteryGameFloorItem[]>();
  const init = async () => {
    setLoading(true);
    getLotteryGames()
      .then(async games => {
        const list = await games.map(game => {
          if (game.uniqueKey === 'indianLottery') {
            game.gamesList = game.gamesList.sort(
              (a, b) =>
                +(b?.otherParams || '').split('stopTime=')[1] -
                +(a?.otherParams || '').split('stopTime=')[1],
            );
          }
          return game;
        });
        const digitIndex = list.findIndex(l => l.uniqueKey === '3DigitV3');
        const hasNotQuickDigitList = list[digitIndex]
          ? list[digitIndex].gamesList.some(
              data => data.gameType.toLowerCase() !== 'quick 3d',
            )
          : false;
        if (digitIndex !== -1 && hasNotQuickDigitList) {
          const digitList = await getDigitList();
          if (digitList && digitList.length) {
            list[digitIndex].gamesList = list[digitIndex].gamesList.map(g => {
              let otherParamsData: Record<string, any> = {};
              try {
                otherParamsData = JSON.parse(g.otherParams);
              } catch (e) {}
              const digitData = digitList.find(
                d => d.id === getUrlParams(g.gameUrl).id,
              );
              otherParamsData.stopTime = digitData
                ? digitData.drawTime + Date.now()
                : '';
              return {
                ...g,
                otherParams: JSON.stringify(otherParamsData),
              };
            });
          }
        }

        setLotteryGames(list);
      })
      .catch(e => {
        const {log} = console;
        log('error', e);
        setLoading(false);
      })
      .finally(() => setLoading(false));
  };
  useEffect(() => {
    init();
  }, []);
  const itemSpan = useCallback((item: LotteryGameCardItem) => {
    return item.picPlaceholder || 1;
  }, []);
  const jumpGame = (v: LotteryGameCardItem, uniqueKey: string) => {
    console.log('gameType', v.gameType);
    if (v.gameType.toLowerCase() === 'dice') {
      const params = getUrlParams(v.gameUrl);
      if (!params.cycle) {
        params.cycle = 1;
      }
      goTo('Dice', params);
    } else if (
      v.name.toLowerCase() === 'wingo' ||
      v.gameType.toLowerCase() === 'color'
    ) {
      goTo('Color', {...getUrlParams(v.gameUrl), name: v.name});
    } else if (
      v.gameType.toLowerCase() === 'quick 3d' ||
      v.gameType.toLowerCase() === 'pick3'
    ) {
      goTo('Digit', {
        ...getUrlParams(v.gameUrl),
        pickGameType:
          v.gameType.toLowerCase() === 'quick 3d' ? 'quick' : 'normal',
      });
    } else if (uniqueKey === 'indianLottery') {
      const lottoInfo = getUrlParams(v.gameUrl) as {
        lotteryId: string;
        wonAmount: `${number}`;
        gameType?: string;
      };
      const other = getUrlParams(v.otherParams) as {
        cycle?: string;
        stopTime?: string;
      };
      if (
        getIndianLotteryData(v).time < new Date().getTime() &&
        lottoInfo.gameType !== 'quick'
      ) {
        showToast(
          'warning',
          `${v.name} is closed now, please choose other game.`,
        );

        return;
      }
      goTo('MixLottery', {
        lotteryID: lottoInfo.lotteryId,
        isQuick: lottoInfo.gameType === 'quick' ? '1' : '0',
        cycle: other.cycle ? +other.cycle : 0,
      });
      return;
    }
  };
  const renderItem = useCallback(
    (item: LotteryGameCardItem, uniqueKey: string, index?: number) => {
      if (uniqueKey === 'indianLottery') {
        return (
          <MixLotteryCard
            key={item.id}
            data={item}
            index={index || 0}
            onClick={() => jumpGame(item, uniqueKey)}
          />
        );
      }
      if (uniqueKey === '3DigitV3') {
        return (
          <DigitCard
            key={item.id}
            data={item}
            index={index || 0}
            onClick={() => jumpGame(item, uniqueKey)}
          />
        );
      }
      return (
        <CasinoGamesCard
          onClick={() => jumpGame(item, uniqueKey)}
          height="9.25rem"
          key={item.id}
          hasDesc={false}
          title={item.name}
          pic={item.gamePic}
          onlyPic
        />
      );
    },
    [],
  );

  const [tabKey, setTabKey] = useState('');
  const itemsRef = useRef<Record<string, HTMLDivElement>>({});
  const startDivRef = useRef<HTMLDivElement>(null);
  const scrollRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (lotteryGames?.length) {
      setTabKey(lotteryGames[0].uniqueKey);
    }
  }, [lotteryGames]);

  const anchorTo = useCallback((key: string) => {
    if (!itemsRef.current[key]) {
      return;
    }
    console.log(itemsRef.current[key]);
    scrollRef.current?.scrollTo({
      top:
        itemsRef.current[key].getBoundingClientRect().top -
        (startDivRef.current?.getBoundingClientRect().top || 0),
      behavior: 'smooth',
    });
    // itemsRef.current[key].scrollIntoView({
    //   behavior: 'smooth',
    //   inline: 'nearest',
    //   block: 'start',
    // });
  }, []);

  useEffect(() => {
    anchorTo(tabKey);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabKey]);
  return (
    <Spin loading={loading}>
      <div className="flex flex-col flex-1 overflow-y-auto" ref={scrollRef}>
        <div
          className={`px-3 py-2 sticky top-0 z-20 ${styles['category-tab']} ${tabWrapClassName}`}>
          <CategoryTab
            activeKey={tabKey}
            onChange={setTabKey}
            tabs={lotteryGames || []}
          />
        </div>
        <div className="flex flex-col gap-5" ref={startDivRef}>
          {lotteryGames?.map((game, index) => {
            return game.gamesList ? (
              <Category
                ref={node => {
                  if (!node) {
                    return;
                  }
                  itemsRef.current[game.uniqueKey] = node;
                }}
                key={index}
                icon={iconsMap[game.uniqueKey].default}
                title={game.name}
                list={game.gamesList}
                col={3}
                itemSpan={itemSpan}
                className={styles['slots-category']}
                renderItem={(item, _index) =>
                  renderItem(item, game.uniqueKey, _index)
                }
              />
            ) : null;
          })}
          <Footer />
        </div>
      </div>
    </Spin>
  );
};
export default LotteryContent;
